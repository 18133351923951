import React, {/*Component,*/ PureComponent } from 'react'
import { CustomCard, Center } from '../../style';
import { Button } from '@material-ui/core';

import './style.css'
//import withAuthProvider, { AuthComponentProps } from '../../services/AuthProvider';

export default class Login extends PureComponent {
    /*componentDidUpdate() {
        if (this.props.isAuthenticated) {
            this.props.history.go('/projects');
        }
    }*/

    render() {
        return (
            <Center>
                <CustomCard>
                    <h1>Login aqui meu amigo</h1>
{                    <Button className='button-login' variant='contained' color='primary'>Login</Button>
}                </CustomCard >
            </Center>
        );
    }
}

//export default withAuthProvider(Login)


/*

const Login = () => {
    const { inicializa, isAuthenticated, logout, login } = LoginController.getInstance();
    useEffect(() => { inicializa() }, []);
    return (
        <Center>
            <CustomCard>
                <h1>Login aqui meu amigo {isAuthenticated.toString()}</h1>
                <Button className='button-login'
                    onClick={() => { isAuthenticated ? logout() : login() }}
                    variant='contained' color='primary'>{isAuthenticated ? 'Logout' : 'Login'}</Button>
            </CustomCard >
        </Center>
    );
}

export default observer(Login)

*/
