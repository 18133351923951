import { ServerService } from "../services/server.service";
import { ClientProject } from "./clientproject";
import { DataBase } from "./database";

export class Server {
  Id: any
  DataBases: Array<DataBase> = []
  Projects: Array<ClientProject> = [];
  Unidades: Array<Number> = []
  LastCheck: Date = new Date()
  UpdaterStatus: string = ""
  GuardianStatus: string = ""
  Internal: boolean = true;
  User: string = "";

  static All = async (): Promise<Array<Server>> => {
    var result = await ServerService.listAll();
    return result.data as Array<Server>;
  }
}