import React from 'react'
import { observer } from 'mobx-react-lite'
import './style.css'
import { IconButton } from '@material-ui/core'
import { Warning, ReportOff, DoneAll, Close } from '@material-ui/icons'
import { Variants } from '../../store/alert.controller'
import AlertStore from '../../store/alert.controller'

const Alert = () => {
    const { isOpen, variant, message, close } = AlertStore.getInstance();

    if (isOpen) {
        return (
            <>
                <div className={"alert " + variant}>
                    <div className="icon">
                        {returnIcon(variant)}
                    </div>
                    <div className="content">
                        {message}
                    </div>
                    <div className="fechar">
                        <IconButton size='small' color='inherit' onClick={close}>
                            <Close />
                        </IconButton>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
            </>
        )
    }

}

function returnIcon(variant: Variants) {
    switch (variant) {
        case Variants.Error:
            return <ReportOff />
        case Variants.Warnig:
            return <Warning />
        case Variants.Success:
            return <DoneAll />
        default:
            break;
    }
}

export default observer(Alert)