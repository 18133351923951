import { Box, BoxProps, styled } from "@material-ui/core";


export const ContentModal = styled(Box)<BoxProps>({
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 400,
    background: '#fff',
    padding: '20px 32px 16px',
    borderRadius: 4,
    boxShadow: '0px 0px 8px 4px rgba(0,0,0,0.12)',
})

export const ModalStyles = {
    margin: 16,
}