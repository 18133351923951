import styled from 'styled-components';

interface IContainer {
  logo: string;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  height: 100%;
  display: block;
  background-image: url(${({ logo }) => logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: 0.1s;
`;
