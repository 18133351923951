import { observable, action } from 'mobx'

export enum Variants {
    Error = 'error',
    Warnig = 'warning',
    Success = 'success'
}

export type Alert = {
    variant: Variants,
    message: string,
    timeToAutoClose?: number
}

export default class AlertStore {
    private static instance: AlertStore;

    static getInstance = () => {
        if (AlertStore.instance) {
            return AlertStore.instance;
        } else {
            AlertStore.instance = new AlertStore()
            return AlertStore.instance;
        }
    }

    @observable
    isOpen: boolean = false

    @observable
    variant: Variants = Variants.Warnig

    @observable
    message: string = ''

    @observable
    timeToAutoClose?: number

    @action
    openAlert = (alert: Alert) => {
        this.variant = alert.variant
        this.message = alert.message
        this.timeToAutoClose = alert.timeToAutoClose
        this.isOpen = true

        if (alert.timeToAutoClose) {
            setTimeout(this.close, alert.timeToAutoClose)
        }
    }

    @action
    close = () => {
        this.variant = Variants.Warnig
        this.message = ''
        this.timeToAutoClose = undefined
        this.isOpen = false
    }

}
