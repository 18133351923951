import { AxiosResponse } from "axios"
import { Project } from "../models/project"
import { ApiResponse } from "../models/response"
import api from "./api"

export class ProjectService {

  static async listAll(): Promise<ApiResponse<Array<Project>>> {
    const response: AxiosResponse<Array<Project>> = await api.get('v1/projects/projectlist');
    return new ApiResponse(response.status === 200, response.status, response.data);
  }

  static async update(project: Project, file: any): Promise<ApiResponse<boolean>> {
    var data: any = {};
    data.Project = project;
    data.File = file;
    console.log("p:", data)
    const response: AxiosResponse<boolean> =
      await api.post('/project',
        data
      );
    return new ApiResponse(response.status === 200, response.status, response.data);
  }

}
