import { ChangeEvent, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Table, Title, ContentModal, ContentButton, ContentArchive, SpanArchive } from "../../style";
import DefaultHeader from "../../components/header";
import api from "../../services/api";
import { Button, IconButton, TextField } from "@material-ui/core";
import { Add, Publish } from "@material-ui/icons";
import { CustomModal } from '../../components/modal'

const ListProjectsPage = () => {
  const [projects, setProjects] = useState<Array<any>>(['']);
  const [project, setProject] = useState<any>();
  const [open, setOpen] = useState(false);
  const [version, setVersion] = useState("");
  const [name, setName] = useState("");
  const [uploadFile, setUploadFile] = useState<string | ArrayBuffer>()

  const handleFileReader = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      let files: FileList = event.target.files;
      setName(files[0].name);

      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        const file = e.target!.result;
        if (file) {
          setUploadFile(file);
        }
      }
    }
  }

  const handleClose = () => {
    setName("");
    setVersion("");
    setOpen(false);
  }

  useEffect(() => {
    async function listProjects() {
      const { data: { data } } = await api.get("api/v1/projects/projectlist");
      setProjects(data[0]);
    }

    listProjects();
  }, []);

  return (
    <>
      <DefaultHeader title="Atualizador Inside / Projetos" />
      <Table className="table">
        <thead>
          <tr>
            <th style={{ width: "100%" }}>Projeto</th>
            <th colSpan={2} >Versão</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((p, i) => (
            <tr key={i}>
              <td>{p.projectId}</td>
              <td>{p.version}</td>
              <td>
                <IconButton color="primary" onClick={() => {
                  setOpen(true)
                  setProject(p)
                }}>
                  <Publish />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <CustomModal
        open={open}
        onClose={handleClose}
      >
        <ContentModal>
            <Title>Publicar versão do projeto</Title>
          <TextField label="Versão" value={version} onChange={(event) => setVersion(event.target.value)} />
          <ContentArchive>
            <Button
              startIcon={<Add />}
              color="primary"
              variant="contained"
              component="label"
            >
              Arquivo
              <input type="file" accept=".zip,.rar,.7zip" hidden onChange={handleFileReader} />
            </Button>
            <SpanArchive>{name}</SpanArchive>
          </ContentArchive>
          <ContentButton>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button color="primary" onClick={() => {
              const uploadProject = {
                ...project,
                version: version,
                files: uploadFile,
              }

              api.post(`/api/v1/projects/${uploadProject.projectId}/publish`, uploadProject);
              handleClose();
            }}
            >
              Confirmar
            </Button>
          </ContentButton>
        </ContentModal>
      </CustomModal>
    </>
  );
};

export default observer(ListProjectsPage);
