import React from 'react'
import { Modal, ModalProps } from "@material-ui/core";
import { ContentModal, ModalStyles } from './style';

type Props = ModalProps

export function CustomModal(props: Props) {

    return (
        <Modal
            style={ModalStyles}
            {...props}
        >
            <ContentModal>
                {props.children}
            </ContentModal>
        </Modal>
    )
}
