import React from 'react';
import * as S from './styles';

import logo from '../../assets/img/icon.png';

const Logo: React.FC = () => (
  <S.Container id="logo" role="logo" logo={logo} />
);

export default Logo;
