import {
  AccountInfo,
  IPublicClientApplication,
  PopupRequest,
} from "@azure/msal-browser";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import React from "react";
import { useHistory } from "react-router-dom";
import { config } from "../../Config";
import * as Styled from "./style";
import {FiLogIn} from 'react-icons/fi';

import Logo from "../../components/logo";

function signOutClickHandler(instance: IPublicClientApplication) {
  const logoutRequest = {
    account: instance.getAccountByHomeId(config.appId),
    mainWindowRedirectUri: "/",
    postLogoutRedirectUri: config.redirectUri,
  };
  instance.logoutPopup(logoutRequest);
}

export function SignOutButton() {
  const { instance } = useMsal();

  return <button onClick={() => signOutClickHandler(instance)}>Sair</button>;
}

export default function Login() {
  const history = useHistory();

  async function SignInClickHandler(instance: IPublicClientApplication) {
    const loginRequest: PopupRequest = {
      account: instance.getAccountByHomeId(config.appId) as
        | AccountInfo
        | undefined,
      redirectUri: config.redirectUri,
      scopes: config.scopes,
    };

    const result = await instance.loginPopup(loginRequest);
    !!result.idToken && history.push("/projects");
  }

  function SignInButton() {
    const { instance } = useMsal();

    return <button onClick={() => SignInClickHandler(instance)}><FiLogIn />Entrar</button>;
  }

  return (
    <Styled.LoginPageWrapper>
      <Styled.LoginContainer>
        <Styled.LoginHeader>
          <h1>Atualizador Inside</h1>
          <Logo />
        </Styled.LoginHeader>

        <Styled.LoginBody>
          <AuthenticatedTemplate>
            <SignOutButton />
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <SignInButton />
          </UnauthenticatedTemplate>
        </Styled.LoginBody>
      </Styled.LoginContainer>
    </Styled.LoginPageWrapper>
  );
}
