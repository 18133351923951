import { action, observable } from "mobx"

class FileSelectController {

  onSelectFile: (param: any) => void = (p) => { };

  idProject: string = "";

  @observable
  fileSelector: any;

  @observable
  fileName: string = "";

  initController = (onSelectFile: (param: any) => void, idProject: string) => {
    console.log("Constructor");
    this.idProject = idProject;
    this.onSelectFile = onSelectFile;
    this.fileSelector = this.buildFileSelector();
    this.fileName = "";
  }

  handleFileSelect = (e: any) => {
    this.fileSelector.click();
    e.preventDefault();
  }

  @action
  selectFile = (ev: Event) => {
    this.fileName = this.fileSelector.files ? this.fileSelector.files[0].name : "";
    let fr: FileReader = new FileReader();
    this.onSelectFile("");
    fr.onloadend = (ev) => { this.handleFile(ev) }
    this.fileName = this.fileSelector.files ? this.fileSelector.files[0].name : "";
    if (this.fileName.replace(".zip", "") !== this.idProject) {
      this.fileName = "";
      window.alert(`Nome do arquivo deve ser ${this.idProject}.zip`);
    }
    else {
      let fr: FileReader = new FileReader();
      fr.onloadend = (ev) => { this.handleFile(ev) }
      fr.readAsDataURL(this.fileSelector.files[0]);
    }
  }

  @action
  handleFile = (e: any) => {
    const content = e.target.result;
    console.log('file content', content)
    this.onSelectFile(content);
  }


  private buildFileSelector = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', '.zip');
    fileSelector.onchange = (ev) => { this.selectFile(ev) };
    return fileSelector;
  }


}

export default new FileSelectController();
