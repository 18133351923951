import styled from "styled-components";

export const LoginPageWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;

  background-image: linear-gradient(45deg, #2b1d40  30%, #5b2a8c 90%);
`;

export const LoginContainer = styled.div`
  height: 80%;
  width: 80%;
  min-height: 16rem;
  min-width: 12rem;
  max-height: 32rem;
  max-width: 24rem;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  filter: drop-shadow(4px 4px 4px #3335);
`;

export const LoginHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 10% 0;
  height: 75%;
  width: 100%;
  border-radius: 0.3rem 0.3rem 0 0;

  h1{
    font-size: 2.5rem;
    color: #000000cc;
    margin: 0 0 2rem 0;
  }

  background-color: #fafafa;

  border-bottom: solid 1px #5553;

  #logo {
    height: 6rem;
    animation: bounce 1s forwards;
  }
`;

export const LoginBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0% 0;
  height: 25%;
  width: 100%;
  background-color: #f1f1f1;
  border-radius: 0 0 0.3rem 0.3rem;

  button {
    background-color: #20afefff;
    border: solid 2px #20afefff;
    color: #fff;
    font-weight: 500;
    width: 210px;
    padding: 0.8%;
    font-size: 1.4rem;
    border-radius: 0.2rem;

    transition: all 0.2s linear;

    :hover {
      background-color: #f4f4f4;
      color: #20afefff;
    }
  }

  svg {
    position: absolute;
    margin: 0.2rem 0 0 5rem;
    font-size: 1.8rem;
    stroke-width: 2.3px;
  }

  @keyframes bounce{
    0% {transform: scale(1.0)}
    50% {transform: scale(1.2)}
    100% {transform: scale(1.0)}
  }
`;
