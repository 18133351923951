import styled from "styled-components";

export const ButtonWrapper = styled.div`
  button {
    background-color: #5b2a8c;
    border: none;
    color: #fff;
    font-weight: 500;
    width: 7rem;
    padding: 0.8%;
    font-size: 1.1rem;

    transition: 0.05s all linear;

    :hover {
      font-size: 1.3rem;
    }
  }
`;

export const Logo = styled.div`
  #logo {
    height: 3rem;
    width: 3rem;
  }
`;
