
import { MuiThemeProvider } from '@material-ui/core';
import Alert from './components/alert/alert'
import Router from './routes';
import { Container } from './style';
import { theme } from './theme/theme'

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Container>
        <Alert />
        <Router />
      </Container>
    </MuiThemeProvider>
  );
}

export default App;
