import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import DefaultHeader from "../../components/header";
import listServerController from "../../store/list-server.controller";
import { CustomCard } from "../../style";
import { CardTotal } from "../../components/card-total/card-total";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";

const ListServerPage = () => {
  const { servers, listServers, filter, original_servers } =
    listServerController;
  useEffect(() => {
    listServers();
  });
  return (
    <>
      <DefaultHeader title="Lista de Servidores Cadastrados" />
      <Container className="justify-content-md-center">
        <div>
          <CardTotal
            count={original_servers.length}
            title={"Servidores Instalados"}
            variant={"valido"}
          ></CardTotal>
          <CardTotal
            count={original_servers
              .map((x) => x.Projects.length)
              .reduce((a, b) => a + b, 0)}
            variant={"neutro"}
            title={"Projetos Monitorados"}
          ></CardTotal>
          <CardTotal
            count={4}
            title={"Projetos Desatualizados"}
            variant={"inconsistente"}
          ></CardTotal>
          <CardTotal
            count={4}
            title={"Atualizares Parados"}
            variant={"inexistente"}
          ></CardTotal>
        </div>
      </Container>
      <CustomCard>
        <Form>
          <Row>
            <Col>
              <Form.Control placeholder="Filtrar" />
            </Col>
            <Col md="auto" xs="auto">
              <Button
                variant="outline-primary"
                onClick={() => {
                  filter("11");
                }}
              >
                Aplicar
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomCard>
      {servers.map((s, i) => (
        <CustomCard>
          <Row>
            <Col>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="primary"
                      eventKey="0"
                      block
                    >
                      Unidades
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ListGroup variant="flush">
                        {s.Unidades.map((u) => (
                          <ListGroup.Item>{u} - Nome da Unidade</ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="primary"
                      eventKey="1"
                      block
                    >
                      Projetos
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <ListGroup variant="flush">
                        {s.Projects.map((p) => (
                          <ListGroup.Item>
                            <Row>
                              <Col>{p.ProjectID}</Col>
                              <Col md="2">{p.CurrentVersion}</Col>
                              <Col md="2">
                                <Button size="sm">Atualizar</Button>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
            <Col lg="2" md="3" xs="auto">
              <CardTotal
                count={s.Projects.length}
                title={"Projetos"}
                variant={s.Projects.length > 0 ? "valido" : "inexistente"}
                miniature={true}
              ></CardTotal>
              <CardTotal
                count={4}
                title={"Desatualizados"}
                variant={s.Projects.length > 0 ? "inexistente" : "valido"}
                miniature={true}
              ></CardTotal>
              <CardTotal
                count={s.Unidades.length}
                title={"Unidades"}
                variant={s.Unidades.length > 0 ? "neutro" : "inexistente"}
                miniature={true}
              ></CardTotal>
              <CardTotal
                count={s.DataBases.length}
                title={"Banco de Dados"}
                variant={s.DataBases.length > 0 ? "neutro" : "inexistente"}
                miniature={true}
              ></CardTotal>
            </Col>
          </Row>
        </CustomCard>
      ))}
    </>
  );
};

export default observer(ListServerPage);
