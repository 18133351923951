import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { SignOutButton } from "../page/Login";

import * as S from "./styles";

type HeaderProps = {
  title: string;
  backButton?: boolean;
};

const DefaultHeader: React.FC<HeaderProps> = ({ title }: HeaderProps) => {
  return (
    <Navbar bg="primary" variant="dark" fixed="top">
      <Navbar.Brand href="/projects">{title}</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/projects"></Nav.Link>
        </Nav>
        <Nav>
          <S.ButtonWrapper>
            <SignOutButton />
          </S.ButtonWrapper>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default DefaultHeader;
