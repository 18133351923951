import { observable, action } from 'mobx'
import { Server } from '../models/server'

class ListServerController {

  @observable
  servers: Server[] = [];

  @observable
  original_servers: Server[] = [];

  @observable
  loading = true;

  @action setData(data: Array<Server>) {
    this.servers = data;
    this.original_servers = Object.assign([], data);
    console.log('Original', this.original_servers)
  }

  @action filter = (filter: string) => {
    this.servers = this.original_servers
      .filter(s => s.Unidades.some(u => u.toString().indexOf(filter) > -1));
  }

  @action load() {
    this.loading = false;
  }

  @action listServers = async () => {
    let response = await Server.All();
    this.setData(response);
    this.load();
  }

  @action
  update(project_id: String): boolean {
    return window.confirm(`Tem certeza que deseja atualizar a versão do ${project_id}`)
  }
}

export default new ListServerController()
