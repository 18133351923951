import React from 'react'

type Props = {
  variant?: 'inconsistente' | 'inexistente' | 'neutro' | 'valido'
  className?: string
  miniature?: boolean
  count: number
  title: string
  onClick?: () => void
}

export const CardTotal: React.FC<Props> = ({ variant = 'neutro', count, title, className = '', onClick, miniature = false }: Props) => {
  const role = 'card-total'
  return (
    <div className={['cardTotalWrap', variant, className, (miniature ? 'miniature' : '')].join(' ')}
      role={role} onClick={onClick}>
      <div className={'total'}>{count}</div>
      <div className={'titulo'}>{title}</div>
    </div>
  )
}
