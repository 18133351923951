import { ProjectService } from "../services/project.service";

export class Project {
  Id: string
  Version: string
  PublicationHistory: Array<any>

  constructor(id: string, version: string, history: any) {
    this.Id = id
    this.Version = version
    this.PublicationHistory = history
  }

  Empty = (): boolean => this.Id === null || this.Id === "";

  static All = async (): Promise<any> => {
    var result = await ProjectService.listAll();

    return result.data;
  }
}
