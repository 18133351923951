import { AxiosResponse } from "axios"
import { ApiResponse } from "../models/response"
import { Server } from "../models/server";
import api from "./api"

export class ServerService {

  static async listAll(): Promise<ApiResponse<Array<Server>>> {
    const response: AxiosResponse<Array<Server>> = await api.get('/server');
    return new ApiResponse(response.status === 200, response.status, response.data);
  }
}