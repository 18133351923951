import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import './custom.scss';

export const Container = styled.div`
    width: 100%;
    max-width: 960px;
    margin:auto;
    min-height: 100vh;
    padding-top: 68px;
    //display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`

export const Header = styled.div`
    width: 100%;
    height: 60px;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: #592b82;
    color: #ffcc00;
    padding: 8px;
    box-shadow: 0px 4px 16px 3px rgba(0,0,0,0.2);
    border-radius: 0px 0px 16px 16px;
    z-index: 1000;
`

export const Table = styled.table`
    width:100%;
    max-width: 960px;
    min-width: 340px;
    border-radius: 10px;
    overflow: hidden;
    border-spacing: 0px;
    margin: auto;
    margin-bottom:15px;
    tr th {
        color: #ffcc00;;
        background: #592b82;
        text-align: start;
    }
    th {
        font-size: 18px;
        color: #fff;
        font-weight: 900;
        padding-top: 19px;
        padding-bottom: 19px;
    }
    td {
        font-size: 16px;
        font-weight:500;
        color: #592b82;
        padding: 12px;
        border-bottom: 1px solid #f2f2f2;
        vertical-align: middle;
    }
    td, th {
        line-height: 1.2;
    }
    td:nth-child(1) ,th:nth-child(1) {
        padding-left: 40px;
    }
    tr {
        background: #fff;
    }
    tr:hover {
        background:#F5F5F5;
    }
    &.miniature {
        th {
            font-size: 12px;
        }

        td:nth-child(1) ,th:nth-child(1) {
            padding-left: 20px;
        }

        td, th {
            line-height: 0;
        }
    }
`

export const CustomCard = styled.div`
  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 10px;
  padding: 16px;
  background-color: #FFF;
  margin: 8px 0px 8px 0px;
`

export const Center = styled.div`
    position: relative;
    top: 50%;
    transform: translateY(50%);
`

export const MessageScreen = styled.div`
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
`

export const LoadingCustom = styled(Loader)`
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.h1`
    font-size: 1.25rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
`

export const ContentModal = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
`

export const ContentButton = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
`


export const ContentArchive = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`

export const SpanArchive = styled.span`
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
