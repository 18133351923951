export class ApiResponse<T>{
    success: boolean
    status: number
    messageError?: string
    data?: T    

    constructor(success: boolean,status: number, data?: any, messageError?: string){
       this.success = success
       this.status = status
       this.messageError = messageError
       this.data = data
    }
}