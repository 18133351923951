import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import LoginPage from "./page/OldLogin/login.page";
import ListProjectsPage from "./page/update-version/list_projects.page";
import ListServerPage from "./page/servers/list_server.page";
import UpdateVersionPage from "./page/update-version/update-version.page";
import Login from "./page/Login";

type IRoute = {
  path: string;
  auth?: boolean;
  exact?: boolean;
  redirect: string;
  component: any;
};

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <AuthRoute path="/" exact component={Login} redirect="/projects" />
        <AuthRoute path="/login" component={LoginPage} redirect="/projects" />
        <AuthRoute
          path="/projects"
          exact
          auth
          component={ListProjectsPage}
          redirect="/newLogin"
        />
        <AuthRoute
          path="/servers"
          auth
          component={ListServerPage}
          redirect="/permissions"
        />
        <AuthRoute
          path="/projects/update/:id"
          auth
          component={UpdateVersionPage}
          redirect="/permissions"
        />
        <AuthRoute
          path="/auth"
          auth
          component={() => <h1>Voce está autenticado</h1>}
          redirect="/permissions"
        />
        {/* default */}
        <Route
          path="*"
          exact
          component={(props: any) => (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;

const AuthRoute: React.FC<IRoute> = ({
  component: Component,
  ...props
}: IRoute) => {
  const logged = useMsal();

  const isAuth = !!logged.accounts[0]?.homeAccountId?.length;

  return (
    <Route
      path={props.path}
      exact={props.exact}
      render={(render) => {
        if (isAuth && props.auth) {
          return <Component />;
        } else if (!isAuth && !props.auth) {
          return <Component />;
        }

        return (
          <Redirect
            to={{ pathname: props.redirect, state: { from: render.location } }}
          />
        );
      }}
    />
  );
};
