import { action, observable, toJS } from 'mobx'
import { UpdateStatus } from '../enums/update_status';
import { Project } from '../models/project'
import { ProjectService } from '../services/project.service';

class UpdateVersionController {

  @observable
  update_version_project: Project = new Project("", "", null);;

  @observable
  file: any = "";

  @observable status: UpdateStatus = UpdateStatus.WAITING;

  updateResult: any;

  @action
  setFile = (file: any) => {
    this.file = file;
  }

  @action
  setId = (id: string) => {
    this.update_version_project = { ...this.update_version_project, Id: id };
  }

  @action
  updateVersionText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.update_version_project = { ...this.update_version_project, Version: event.target.value.replace(/[^\d\s.]/, "") };
  }

  @action
  setStatus = (newStatus: UpdateStatus) => {
    this.status = newStatus;
  }

  @action
  updateVersion = async (current_version: string) => {
    if (this.validateVersion(current_version)) {
      this.setStatus(UpdateStatus.UPDATING);
      await ProjectService.update(toJS(this.update_version_project), this.file)
        .then(res => {
          this.updateResult = res.data;
          console.log(this.updateResult);
          this.setStatus(this.updateResult.Success ? UpdateStatus.FINISH : UpdateStatus.ERROR);
        })
        .catch(ex => console.error("Update Error:", ex));
    }
  }

  getMessage = (): string => {
    switch (this.status) {
      case UpdateStatus.UPDATING:
        return "Publicando projeto no FTP, por favor aguarde, isso pode levar alguns minutos.";
      case UpdateStatus.ERROR:
        var message = "Ops, ocorreu um erro ao publicar o projeto.";
        if (!this.updateResult.InstallFile)
          message += " Erro ao publicar arquivo para instalação.";
        else if (!this.updateResult.UpdateFile)
          message += " Erro ao publicar arquivo de atualização(_atualiza).";
        else if (!this.updateResult.VersionFile)
          message += " Erro ao atualizar arquivo de versão.";
        else if (!this.updateResult.InstallFile)
          message += " Erro ao atualizar versão no bando de dados do atualizar.";
        return message;
      case UpdateStatus.FINISH:
        return this.update_version_project.Id + "(" + this.update_version_project.Version + ") publicado com sucesso!";
      default:
        return "";
    }
  }

  validateVersion = (version: string): boolean => {
    var new_version: string[] = this.update_version_project.Version.split('.');
    var current_version: string[] = version.split('.');
    var isvalid = true;
    if (new_version === current_version) {
      window.alert("Nova versão deve ser maior do que a versão atual.");
      isvalid = false;
    } else if (new_version.length !== current_version.length) {
      window.alert("Formato de versão não é compativel com a versão anterior.");
      isvalid = false;
    } else {
      new_version.forEach((nw, idx) => {
        if (parseInt(nw) < parseInt(current_version[idx])) {
          isvalid = false;
          window.alert("Nova versão não pode ser menor do que a versão anterior.");
        }
      });
    }
    return isvalid;
  }
}
export default new UpdateVersionController()
