import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import FileSelectController from './fileSelectController';
import { BsUpload } from "react-icons/bs";


type FileDialogeProps = {
  onSelectFile: (p: string) => void;
  disabled?: boolean
  idProject: string
}

function FileDialog(props: FileDialogeProps) {
  const { handleFileSelect, fileName, initController } = FileSelectController;

  useEffect(() => {
    initController(props.onSelectFile, props.idProject);
  });

  return (
    <>
      <Row>
        <Col>
          <Form.Control readOnly={true} value={fileName} placeholder="Selecione o zip para publicação" />
        </Col>
        <Col md="auto">
          <Button onClick={handleFileSelect} disabled={props.disabled}>
            <BsUpload size="1.3em" />
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default observer(FileDialog);
