import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import FileDialog from "../../components/file-select/selectFileButton"
import UpdateVersionController from "../../store/update-version.controller"
import { CustomCard, MessageScreen } from "../../style"
import { useParams } from "react-router";
import { useHistory } from "react-router-dom"
import { Button, Col, Form, Row, Spinner } from "react-bootstrap"
import { UpdateStatus } from "../../enums/update_status"
import DefaultHeader from "../../components/header"

type ParamsType = {
  id: string;
}

const UpdateVersionPage = () => {
  let { id } = useParams<ParamsType>();
  let history = useHistory<any>();
  const { update_version_project, updateVersion, updateVersionText, setId, setFile, file, status, getMessage } = UpdateVersionController;
  useEffect(() => {
    setId(id)
  });
  return (
    < >
      <DefaultHeader title={id} backButton={true} />
      <CustomCard>
        <Form>
          <Form.Group controlId="updateVersionFormInfo">
            <Row>
              <Col>
                <Form.Label>Projeto</Form.Label>
                <Form.Control value={id} readOnly={true} />
              </Col>
              <Col>
                <Form.Label>Versão Atual</Form.Label>
                <Form.Control value={history.location.state.version} readOnly={true} />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group controlId="updateVersionFormValues">
            <Row>
              <Col>
                <Form.Label>Arquivo de Publicação</Form.Label>
                <FileDialog onSelectFile={setFile} disabled={status === UpdateStatus.FINISH || status === UpdateStatus.UPDATING} idProject={id} />
              </Col>
              <Col>
                <Form.Label>Nova Versão</Form.Label>
                <Form.Control name="newVersion" value={update_version_project.Version} onChange={updateVersionText}
                  disabled={status === UpdateStatus.FINISH || status === UpdateStatus.UPDATING} />
              </Col>
            </Row>
          </Form.Group>
          <Row>
            <Col md="auto">
              <Button onClick={() => { updateVersion(history.location.state.version) }}
                disabled={file === "" || update_version_project.Version === "" || status === UpdateStatus.UPDATING} >
                {
                  status === UpdateStatus.UPDATING ?
                    <>
                      < Spinner animation="border" as="span" size="sm" role="status" aria-hidden="true" />
                      <span> Publicando...</span>
                    </>
                    : status === UpdateStatus.FINISH ? <span>Retornar a Lista</span>
                      : <span>Publicar</span>
                }
              </Button>
            </Col>
            <Col>
              {
                status !== UpdateStatus.WAITING &&
                <MessageScreen
                  className={("alert-" +
                    (status === UpdateStatus.UPDATING ? "warning" :
                      status === UpdateStatus.ERROR ? "danger" :
                        "success"))}>
                  {getMessage()}
                </MessageScreen>
              }
            </Col>

          </Row>

        </Form>
      </CustomCard>
    </>


    // <Center>
    //   <Card>

    //     <Title>Publicar Nova Versão {id}</Title>
    //     <form className="update-form">
    //       <TextField label="Projeto" fullWidth variant="outlined" disabled value={id} />
    //       <TextField label="Versão Atual" disabled variant="outlined" aria-readonly={true} value={history.location.state.version} />
    //       <TextField label="Nova Versão"
    //         variant='outlined'
    //         value={update_version_project.Version}
    //         name="Version"
    //         onChange={updateVersionText}
    //       />
    //       <FileDialog onSelectFile={setFile} />
    //     </form>
    //     <Button onClick={() => { updateVersion() }} disabled={file == ""} >
    //       {
    //         status == UpdateStatus.UPDATING ?
    //           < Spinner animation="border" as="span" size="sm" role="status" aria-hidden="true" >
    //             <span> Atualizando</span>
    //           </ Spinner>
    //           : <span>Atualizar Versão</span>
    //       }
    //     </Button>
    //     <Alert variant="info">
    //       Por favor aguarde, isso pode demorar alguns minutos.
    //     </Alert>
    //   </Card>
    // </Center >
  )
}

export default observer(UpdateVersionPage)
